const environment = {
	api: {
		url: "https://api-demo.ampmemberships.com",
	},
	localhost: {
		tenantKey: process.env.LOCALHOST_TENANTKEY??"",
	},
	aws: {
		url: "https://demo-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	appInsights: {
		instrumentationKey: "9e720328-d9dc-4e2d-8aa4-cc696ade20fc" ,
	},
	sentry: {
		dsn: "https://0c3e52eb1c06457f8f7a231343f7d3f4@o641321.ingest.sentry.io/6761335",
	},
	mapbox: {
		apiKey: "pk.eyJ1IjoiaW5zaXRlbnhhbXAiLCJhIjoiY2w2NnZpYXB6MDM0bjNjcno2cjJ2a2o5ZyJ9.FhL2-3SmRc25dEQD7Qc4_Q" ,
	},
};

export default environment;
